import wx from 'weixin-js-sdk' // 引入微信SDK
import axios from "axios";

function wxinitAPIs(site_url) {
    let url = encodeURIComponent(location.href.split('#')[0])
    // 微信分享朋友圈
    axios.get('/api/v1/micro/wechat/we_chat_share_to_moments',{
        params:{
            site_url:site_url,
            url:url,
        }
    }).then(res=>{
        if(res.data.code == 200){
            let params = res.data.data;
            // this.$cookies.set('wxinfo',res.data.data)
            wx.config({
                debug: false ,// 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
                appId: params.appId, // 必填，公众号的唯一标识
                timestamp: params.timestamp, // 必填，生成签名的时间戳
                nonceStr:  String(params.nonceStr), // 必填，生成签名的随机串
                signature: String(params.signature),// 必填，签名，见附录1
                jsApiList: [
                    // 必填，所有要调用的 API 都要加到这个列表中
                    "updateTimelineShareData",
                    "updateAppMessageShareData",
                ]
            });
            wx.ready(function () { // 在这里调用 API
                
                //分享到朋友圈
                wx.updateTimelineShareData({
                    title: params.share_title, // 分享标题
                    desc: params.share_description, // 分享描述
                    link: params.link, // 分享链接
                    imgUrl: params.share_image_url, // 分享图标
                    success: function (){ // 用户确认分享后执行的回调函数
                        //alert('分享成功');
                    },
                    cancel: function (){ // 用户取消分享后执行的回调函数
                        //alert('取消分享');
                    },
                    fail: function(){ // 用户分享失败后执行的回调函数
                        //   alert('分享失败',params);
                    }
                });
                //分享给朋友
                wx.updateAppMessageShareData({
                    title: params.share_title, // 分享标题
                    desc: params.share_description, // 分享描述
                    link: params.link, // 分享链接
                    imgUrl: params.share_image_url, // 分享图标
                    success: function (){ // 用户确认分享后执行的回调函数
                        // alert('分享朋友成功');
                    },
                    cancel: function (){ // 用户取消分享后执行的回调函数
                        // alert('取消分享朋友');
                    },
                    fail: function(){ // 用户分享失败后执行的回调函数
                        // alert('分享朋友失败');
                    }
                });
            })
        }
    })
}

  export default  wxinitAPIs