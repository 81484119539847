import axios from "axios";

export default {
    // 上传文件至七牛
    upload_images(store, v) {
        return axios.post("/api/v1/micro/qiniu/upload_images", v)
    },
    // 上传base64至七牛
    upload_base_images(store, v) {
        return axios.post("/api/v1/micro/qiniu/upload_base_images", v)
    },
    // 获取七牛云上传token
    get_verify_space_size(store, v) {
        return axios.get("/api/v1/micro/qiniu/get_verify_space_size", {
            params: {
                site_id: v.site_id,
                size: v.size,
                storage_space: v.storage_space
            }
        })
    },
    // 首页展示
    front_desk_home_show(store, v) {
        return axios.get("/api/v1/micro/module/front_desk_home_show", {
            params: {
                site_url: v.site_url,
            }
        })
    },
    // 模块内容展示
    display_module_content(store, v) {
        return axios.post("/api/v1/micro/module/display_module_content", v)
    },
    //云展厅
    cloud_showroom_show(store, v) {
        return axios.get("/api/v1/micro/cloudShowroom/cloud_showroom_show", {
            params: {
                page_id: v.page_id,
                module_id: v.module_id
            }
        })
    },
    // 云展厅纯图模式
    get_front_desk_pure_picture(store, v) {
        return axios.get("/api/v1/micro/cloudShowroom/get_front_desk_pure_picture", {
            params: {
                site_id: v.site_id,
                module_id: v.module_id,
                showroom_type: v.showroom_type,
                page: v.page,
                page_num: v.page_num
            }
        })
    },
    // 云展厅2D模式
    get_two_d_showroom(store, v) {
        return axios.get("/api/v1/micro/cloudShowroom/get_front_desk_two_d_showroom", {
            params: {
                site_id: v.site_id,
                module_id: v.module_id,
                showroom_type: v.showroom_type,
                page: v.page,
                page_num: v.page_num
            }
        })
    },
    //展厅展示图片分类列表
    showroom_picture_list(store, v) {
        return axios.get("/api/v1/micro/cloudShowroom/showroom_show_picture_list", {
            params: {
                site_id: v.site_id,
                show_grouping: v.show_grouping,
            }
        })
    },
    //展厅展示视频分类列表
    showroom_video_list(store, v) {
        return axios.get("/api/v1/micro/cloudShowroom/showroom_show_video_list", {
            params: {
                site_id: v.site_id,
                show_grouping: v.show_grouping,
            }
        })
    },
    //展厅展示新闻资讯分类列表
    showroom_news_list(store, v) {
        return axios.get("/api/v1/micro/cloudShowroom/showroom_show_news_list", {
            params: {
                site_id: v.site_id,
                show_grouping: v.show_grouping,
            }
        })
    },
    //展厅展示Logo墙分类列表
    showroom_logo_wall_list(store, v) {
        return axios.get("/api/v1/micro/cloudShowroom/showroom_show_logo_wall_list", {
            params: {
                site_id: v.site_id,
                show_grouping: v.show_grouping,
            }
        })
    },
    //纯图模式添加单图访问量
    add_single_image_visits(store, v) {
        return axios.post("/api/v1/micro/cloudShowroom/add_single_image_visits", {
            site_id: v.site_id,
            id: v.id,
        })
    },
    //密码登录
    password_login(store, v) {
        return axios.get("/api/v1/micro/login/password_login", {
            params: {
                account: v.account,
                password: v.password
            }
        })
    },
    //验证码登录
    sms_login(store, v) {
        return axios.get("/api/v1/micro/login/sms_login", {
            params: {
                mobile: v.mobile,
                code: v.code
            }
        })
    },
    //获取验证码
    get_captcha(store, v) {
        return axios.post("/api/v1/micro/common/send_sms_captcha", {
            type: v.type,
            mobile: v.mobile,
        })
    },
    //注册
    light_registration(store, v) {
        return axios.post("/api/v1/micro/login/light_official_website_registration", {
            username: v.username,
            mobile: v.mobile,
            verification_code: v.verification_code,
            identity_give: v.identity_give,
            organization: v.organization,
            password: v.password,
        })
    },
    //文章列表
    news_information_list(store, v) {
        return axios.get("/api/v1/micro/news/show_news_information_list", {
            params: {
                site_id: v.site_id,
                module_id: v.module_id,
                page: v.page,
                page_num: v.page_num
            }
        })
    },
    //文章详情
    show_news_info(store, v) {
        return axios.get("/api/v1/micro/news/show_news_info", {
            params: {
                id: v.id
            }
        })
    },
    //图片集(新版图片列表接口 get_image_data_list)
    get_image_list(store, v) {
        return axios.get("/api/v1/micro/media/get_image_data_list", {
            params: {
                site_id: v.site_id,
                module_id: v.module_id,
            }
        })
    },

    //文档列表
    show_document(store, v) {
        return axios.get("/api/v1/micro/media/get_file_list", {
            params: {
                site_id: v.site_id,
                module_id: v.module_id,
                // status:v.status,
                keywords: v.keywords,
            }
        })
    },

    // 资料下载配置
    front_end_file_download_configuration(store, v) {
        return axios.post("/api/v1/micro/media/front_end_file_download_configuration", {
            site_id: v.site_id,
        })
    },

    // 文档下载量
    set_download(store, v) {
        return axios.post("/api/v1/micro/media/set_file_downloads", {
            id: v.id,
        })
    },

    //合作伙伴
    show_partner_info(store, v) {
        return axios.get("/api/v1/micro/partner/show_partner_info", {
            params: {
                site_id: v.site_id,
                module_id: v.module_id,
            }
        })
    },
    //视频展示
    get_video_list(store, v) {
        return axios.get("/api/v1/micro/media/get_video_data_list", {
            params: {
                site_id: v.site_id,
                module_id: v.module_id,
                status: v.status
            }
        })
    },
    // 获取日程列表
    get_schedule_library_info_list(store, v) {
        return axios.get("/api/v1/micro/schedule/get_schedule_library_info_list", {
            params: {
                page_id: v.page_id,
                // module_id:v.module_id, 
            }
        })
    },
    // 获取日程信息
    get_schedule_venue_info_list(store, v) {
        return axios.get("/api/v1/micro/schedule/get_schedule_venue_info_list", {
            params: {
                library_id: v.library_id,
                // schedule_library_id:v.schedule_library_id,
                // module_id:v.module_id, 
            }
        })
    },
    // 获取全部场馆
    get_schedule_venue_info_all_list(store, v) {
        return axios.get("/api/v1/micro/schedule/get_schedule_venue_info_all_list", {
            params: {
                page_id: v.page_id,
                // schedule_library_id:v.schedule_library_id,
                // module_id:v.module_id, 
            }
        })
    },
    // 获取全部议程
    get_schedule_agendum_info_all_list(store, v) {
        return axios.get("/api/v1/micro/schedule/get_schedule_agendum_info_all_list", {
            params: {
                page_id: v.page_id,
                // schedule_library_id:v.schedule_library_id,
                // module_id:v.module_id, 
            }
        })
    },
    // 获取参会者
    get_list_expert_contact_list(store, v) {
        return axios.get("/api/v1/micro/doctor/get_expert_contact_list", {
            params: {
                module_id: v.module_id,
                site_id: v.site_id,
                keywords: v.keywords,
            }
        })
    },
    // 获取直播库列表
    get_library_live_list(store, v) {
        return axios.get("/api/v1/micro/live/get_library_live_list", {
            params: {
                module_id: v.module_id,
                site_id: v.site_id,
            }
        })
    },
    // 获取日程列表
    get_library_live_studio_list(store, v) {
        return axios.get("/api/v1/micro/live/get_library_live_studio_list", {
            params: {
                module_id: v.module_id,
                id: v.id,
            }
        })
    },
    //视频播放量
    get_view_count(store, v) {
        return axios.post("/api/v1/micro/media/view_play_volume", {
            id: v.id,
        })
    },
    // 站点访问量
    site_view_count(store, v) {
        return axios.get("/api/v1/micro/site/site_view_count", {
            params: {
                site_id: v.site_id,
            }
        })
    },
    // 模块点击量
    inc_module_usage_statistics(store, v) {
        return axios.post("/api/v1/micro/data/inc_module_usage_statistics", {
            site_id: v.site_id,
            module: v.module,
            module_id: v.module_id
        })
    },
    // 收集用户信息
    user_information_collection(store, v) {
        return axios.get("/api/v1/micro/data/user_information_collection", {
            params: {
                site_id: v.site_id,
                referral_code: v.referral_code,
            }
        })
    },

    // 微信
    official_account_login(store, v) {
        return axios.get("/api/v1/micro/wechat/official_account_login", {
            params: {
                site_url: v.site_url,
            }
        })
    },

    // 微信回调
    official_account_call_back(store, v) {
        return axios.get("/api/v1/micro/wechat/official_account_call_back", {
            params: {
                code: v.code,
                key: v.key,
                site_url: v.site_url
            }
        })
    },

    // 第一次查看注册列表
    form_details(store, v) {
        return axios.get("/api/v1/micro/form/form_details", {
            params: {
                site_url: v.site_url,
            }
        })
    },
    // 注册之后查询注册列表
    form_query(store, v) {
        return axios.get("/api/v1/micro/form/get_user_register_info", {
            params: {
                site_id: v.site_id,
            }
        })
    },
    // 修改注册表单信息 
    upd_user_register_info(store, v) {
        return axios.post("/api/v1/micro/form/upd_user_register_info", {
            site_id: v.site_id,
            nickname_field: v.nickname_field,
            id: v.id,
            form: v.form,
            code: v.code
        })
    },
    // 提交注册表单信息
    add_front_desk_registration_form(store, v) {
        return axios.post("/api/v1/micro/form/add_front_desk_registration_form", {
            site_url: v.site_url,
            form: v.form,
            nickname: v.nickname,
            code: v.code
        })
    },

    // 全局注册观看展示 (未使用)
    global_registration_form(store, v) {
        return axios.get("/api/v1/micro/form/global_registration_form", {
            params: {
                site_url: v.site_url,
            }
        })
    },

    // 模块注册观看展示 (未使用)
    module_registration_form(store, v) {
        return axios.get("/api/v1/micro/form/module_registration_form", {
            params: {
                site_url: v.site_url,
                random_number: v.random_number,
            }
        })
    },

    // 站点是否强制注册观看 (未使用)
    site_register(store, v) {
        return axios.get("/api/v1/micro/module/site_register", {
            params: {
                site_url: v.site_url,
            }
        })
    },

    // 模块是否强制注册观看 (未使用)
    module_register(store, v) {
        return axios.get("/api/v1/micro/module/module_register", {
            params: {
                random_number: v.random_number,
            }
        })
    },


    // 身份赋予
    get_user_form_identity(store, v) {
        return axios.get("/api/v1/micro/form/get_user_form_identity", {
            params: {
                studio_id: v.studio_id,
                site_id: v.site_id,
                // form_id:v.form_id,
            }
        })
    },
    // 嘉宾的学术任务
    get_academic_assignments(store, v) {
        return axios.get("/api/v1/micro/schedule/get_academic_assignments", {
            params: {
                page_id: v.page_id,
                people_id: v.people_id
            }
        })
    },
    //会场内嘉宾议程信息
    get_academic_assignments_info(store, v) {
        return axios.get("/api/v1/micro/schedule/get_academic_assignments_info", {
            params: {
                venue_id: v.venue_id,
            }
        })
    },
    // 获取学术任务
    getAcademicTasks(store, v) {
        return axios.get("/api/v1/micro/schedule/get_guest_academic_assignments", {
            params: {
                page_id: v.page_id,
                people_id: v.people_id
            }
        })
    },
    // 获取议程内详细信息
    get_guest_agendum_info(store, v) {
        return axios.get("/api/v1/micro/schedule/get_guest_agendum_info", {
            params: {
                agendum_id: v.agendum_id,
            }
        })
    },
    // 检测站点是否全局表单注册
    get_form_global_registration(store, v) {
        return axios.get("/api/v1/micro/form/get_form_global_registration", {
            params: {
                site_url: v.site_url,
            }
        })
    },
    //C端端微信登录授权key
    get_pc_login_key(store, v) {
        return axios('/api/v1/micro/wechat/get_pc_login_key', v)
    },
    // C端微信授权token
    get_pc_login_token(store, v) {
        return axios.get('/api/v1/micro/wechat/get_pc_login_token', {
            params: {
                key: v.key,
            }
        })
    },
    // C端获取微信公众号登录授权 
    official_account_pc_login(store, v) {
        return axios.get('/api/v1/micro/wechat/official_account_pc_login', {
            params: {
                key: v.key,
            }
        })
    },
    //C端用户查看历史记录
    list_history_record(store, v) {
        return axios('/api/v1/micro/userTerminal/list_history_record', {
            params: v
        })
    },

    //C端用户删除历史记录
    delete_history_record(store, v) {
        return axios.delete('/api/v1/micro/userTerminal/delete_history_record', {
            params: v
        })
    },

    //C端用户添加收藏
    add_collection_record(store, v) {
        return axios.post('/api/v1/micro/userTerminal/add_collection_record', v)
    },

    //C端取消站点收藏
    unfavorite_the_site(store, v) {
        return axios.delete('/api/v1/micro/userTerminal/unfavorite_the_site', {
            params: v
        })
    },

    // //C端用户删除收藏记录
    // delete_collection_record(store, v){
    //     return  axios.delete('/api/v1/micro/userTerminal/delete_collection_record', {
    //         params: v
    //     })
    // },

    //C端用户查看收藏记录
    list_collection_record(store, v) {
        return axios('/api/v1/micro/userTerminal/list_collection_record', {
            params: v
        })
    },

    //C端用户关注日程
    add_follow_the_schedule(store, v) {
        return axios.post('/api/v1/micro/userTerminal/add_follow_the_schedule', v)
    },

    //C端用户查看关注日程
    list_follow_the_schedule(store, v) {
        return axios('/api/v1/micro/userTerminal/list_follow_the_schedule', {
            params: v
        })
    },

    //C端用户删除关注日程
    delete_follow_the_schedule(store, v) {
        return axios.delete('/api/v1/micro/userTerminal/delete_follow_the_schedule', {
            params: {
                id: v
            }
        })
    },

    //站点浏览
    visit_site_record(store, v) {
        return axios.post('/api/v1/micro/userTerminal/visit_site_record', v)
    },

    //获取站点收藏状态
    get_site_record_collection_status(store, v) {
        return axios('/api/v1/micro/userTerminal/get_site_record_collection_status', {
            params: v
        })
    },



    //显示付款页
    show_payment_page(store, v) {
        return axios('/api/v1/micro/pay/show_payment_page', {
            params: v
        })
    },
    // 前台展示防疫码
    get_epidemic_prevention_code(store, v) {
        return axios('/api/v1/micro/healthy/get_epidemic_prevention_code', {
            params: v
        })
    },

    // 用户上传防疫码
    user_add_epidemic_prevention_code(store, v) {
        return axios.post('/api/v1/micro/healthy/user_add_epidemic_prevention_code', v)
    },

    // 用户查看防疫码
    check_epidemic_prevention_code(store, v) {
        return axios('/api/v1/micro/healthy/check_epidemic_prevention_code', {
            params: v
        })
    },

    //上传支付凭证
    upload_payment_voucher(store, v) {
        return axios.post('/api/v1/micro/pay/upload_payment_voucher', v)
    },

    //展示用户唯一凭证/api/v1/micro/pay/get_user_certificate?site_id=yo3psuo1lCbKTfwr
    get_user_certificate(store, v) {
        return axios('/api/v1/micro/pay/get_user_certificate', {
            params: v
        })
    },

    // 前台酒店信息展示
    get_hotel_information(store, v) {
        return axios('/api/v1/micro/hotel/get_hotel_information', {
            params: v
        })
    },

    // 预订酒店时判断是否报名缴费
    get_hotel_booking(store, v) {
        return axios('/api/v1/micro/hotel/get_hotel_booking', {
            params: v
        })
    },

    //信息有误，注销用户唯一凭证
    del_payment_voucher(store, v) {
        return axios.delete('/api/v1/micro/pay/del_payment_voucher', {
            params: v
        })
    },

    // 添加用户入住酒店信息
    add_hotel_user_info(store, v) {
        return axios.post('/api/v1/micro/hotel/add_hotel_user_info', v)
    },

    // 获取酒店凭证号
    get_hotel_voucher_number(store, v) {
        return axios('/api/v1/micro/hotel/get_hotel_voucher_number', {
            params: v
        })
    },

    // 查看用户预订酒店信息
    check_hotel_user_info(store, v) {
        return axios('/api/v1/micro/hotel/check_hotel_user_info', {
            params: v
        })
    },

    //信息错误，注销预约酒店
    del_hotel_user_info(store, v) {
        return axios.delete('/api/v1/micro/hotel/del_hotel_user_info', {
            params: v
        })
    },

    //查看站点授权状态
    get_authorization_switch(store, v) {
        return axios('/api/v1/micro/authorization/get_authorization_switch', {
            params: {
                site_url: v
            }
        })
    },

    //检测用户是否已注册表单
    check_registered_the_forms(store, v) {
        return axios('/api/v1/micro/form/check_registered_the_forms', {
            params: v
        })
    },

    //验证身份赋予用户信息
    verify_identity_user_info(store, v) {
        return axios('/api/v1/micro/authorization/verify_identity_user_info', {
            params: v
        })
    },

    //展示授权密码标题
    get_password_exhibit(store, v) {
        return axios('/api/v1/micro/authorization/get_password_exhibit', {
            params: v
        })
    },

    //授权密码验证
    password_verify(store, v) {
        return axios.post('/api/v1/micro/authorization/password_verify', v)
    },

    //获取身份赋予配置链接
    get_identity_info_exhibit(store, v) {
        return axios('/api/v1/micro/authorization/get_identity_info_exhibit', {
            params: v
        })
    },

    // 征文添加
    add_front_end_user_file(store, v) {
        return axios.post('/api/v1/micro/addText/add_front_end_user_file', v)
    },
    //征文文案设置  
    front_end_call_for_papers(store, v) {
        return axios.post('/api/v1/micro/addText/front_end_call_for_papers', v)
    },
    // 上传征文展示
    get_front_end_user_file_list(store, v) {
        return axios.get('/api/v1/micro/addText/get_front_end_user_file_list', {
            params: {
                site_id: v.site_id,
                keywords: v.keywords,
                page: v.page,
                page_num: v.page_num
            }
        })
    },
    // 删除已上传的征文
    del_front_end_user_file(store, v) {
        return axios.delete('/api/v1/micro/addText/del_front_end_user_file', {
            params: v
        })
    },
    // 修改已上传的征文名称
    set_front_end_user_file(store, v) {
        return axios.post('/api/v1/micro/addText/set_front_end_user_file', v)
    },
    //会议地址 
    get_front_end_meeting_address(store, v) {
        return axios.get('/api/v1/micro/meeting/get_front_end_meeting_address', {
            params: {
                site_id: v.site_id,
            }
        })
    },
    // 推荐模块信息数据
    display_recommend_module_data(store, v) {
        return axios.get('/api/v1/micro/module/display_recommend_module_data', {
            params: {
                site_id: v.site_id,
            }
        })
    },
    // 会议kv获取
    get_front_calendar_kv(store, v) {
        return axios.get('/api/v1/micro/calendar/get_front_calendar_kv', {
            params: {
                site_id: v.site_id,
            }
        })
    },
    // 会议日历列表
    get_front_calendar_list(store, v) {
        return axios.get('/api/v1/micro/calendar/get_front_calendar_list', {
            params: {
                site_id: v.site_id,
            }
        })
    },
    // 会场列表信息
    get_front_calendar_conference_list(store, v) {
        return axios.get('/api/v1/micro/calendar/get_front_calendar_conference_list', {
            params: {
                calendar_id: v.calendar_id,
            }
        })
    },
    // 会议日历详情
    get_front_calendar_conference_info(store, v) {
        return axios.get('/api/v1/micro/calendar/get_front_calendar_conference_info', {
            params: {
                id: v.id,
            }
        })
    },
    //嘉宾信息导出
    export_guest_academic(store, v) {
        return axios.get('/api/v1/micro/schedule/export_guest_academic', {
            params: {
                page_id: v.page_id,
                people_id: v.people_id
            }
        })
    },
    //获取loading定制
    get_custom_loading(store, v) {
        return axios.get('/api/v1/micro/custom/get_custom_loading', {
            params: {
                site_url: v.site_url,
            }
        })
    },

    user_sign(store, v) {
        return axios.post('/api/v1/micro/form/user_sign', v)
    },

    get_user_register_info(store, v) {
        return axios.get('/api/v1/micro/form/form_details', {
            params: {
                site_url: v.site_url
            }
        })
    },
}