<template>
    <!-- 资料下载 -->
    <div class="fileBox" id="scroll" :style="{'--themecolor':themecolors}">
        <div class="fileConter">
            <van-search class="search" shape="round" v-model="keywords" @input="show_document()"
                placeholder="请输入文档名称查询" />
            <van-loading :color="themecolors" :text-color="themecolors" v-if="Loading" />
            <van-empty :image="require('@/assets/null.svg')" v-else-if="filedata.length == 0" />
            <div v-else>
                <ul v-for="(item,index) in filedata" :key="index">
                    <div class="conbox">
                        <div class="conleft"> <img :src="item.cover_url"></div>
                        <div class="conright">
                            <p class="file_title">{{item.file_title}}</p>
                            <div class="bottomBox">
                                <div class="rightBot">
                                    <div class="time">{{item.created_at}}</div>
                                    <div class="rigbto">
                                        <span style="color: #888888;min-width:70px;">{{item.size}}</span>
                                        <span :style="{color:themecolors,'margin-left':'8px'}">{{item.format}}</span>
                                        <span style="margin-left:20px;color: #888888;" v-if="downloads"><img
                                                src="@/assets/otherTU/download.svg" alt=""> {{item.downloads}}</span>
                                    </div>
                                </div>
                                <div class="leftBot" @click="fileLink(item,1)" v-if="download">
                                    <!-- <a download="w3logo" :href="item.file_link_url"> -->
                                    <a download="w3logo">
                                        <span>下载</span>
                                    </a>

                                </div>
                                <div class="leftBot" @click="fileLink(item,2)" v-if="preview">
                                    <!-- <a download="w3logo" :href="item.file_link_url"> -->
                                    <a download="w3logo">
                                        <span>预览</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </ul>
            </div>
        </div>
        <Support></Support>
    </div>
</template>
<script>
import Support from '@/components/support';
import { mapState } from 'vuex'
export default {
    components: {
        Support
    },
    data() {
        return {
            filedata: [],
            Loading: false,
            keywords: "",
            preview: 0,//预览开关
            downloads: 0,//下载数量开关
            download: 0,//下载开关
            previewUrl: "",
            iframe_Loading: true,
        }
    },
    props: {
        app: Object
    },
    created() {
        let obj = {
            module_id: this.app.random_number,
            page_id: this.app.page_id,
            site_id: this.site_id,
            showroom_type: this.app.configure.module_value.showroom_type
        }
        this.params = obj;
    },
    mounted() {
        this.front_end_file_download_configuration();
        this.show_document();
    },
    computed: {
        ...mapState(['themecolors', 'themecolor','site_id'])
    },
    methods: {
        front_end_file_download_configuration() {
            this.$store.dispatch("front_end_file_download_configuration", {
                site_id: this.params.site_id,
            }).then(res => {
                if (res.data.code == 200) {
                    this.preview = res.data.data.preview
                    this.downloads = res.data.data.downloads
                    this.download = res.data.data.download
                }
            })
        },
        show_document() {
            this.Loading = true
            this.$store.dispatch("show_document", {
                site_id: this.params.site_id,
                module_id: this.params.module_id,
                // status:0,
                keywords: this.keywords,
            }).then(res => {
                this.Loading = false
                if (res.data.code == 200) {
                    document.title = res.data.data.module_title ? res.data.data.module_title : (sessionStorage.getItem('wei-title') || '轻微站');
                    this.filedata = res.data.data.module_content;
                }
            })
        },
        //下载量接口
        fileLink(item, type) {
            if (type == 1) {
                if ((navigator.userAgent.indexOf('MSIE') >= 0) && (navigator.userAgent.indexOf('Opera') < 0)) {
                    var fileURL = window.open(item.file_url, "_blank", "height=0,width=0,toolbar=no,menubar=no,scrollbars=no,resizable=on,location=no,status=no");
                    fileURL.document.execCommand("SaveAs");
                    fileURL.window.close();
                    fileURL.close();
                } else if (window.navigator.msSaveBlob) {
                    window.open(item.file_url);
                } else {
                    let a = document.createElement('a')  // 创建a标签
                    let url = item.file_url
                    fetch(url).then(res => res.blob()).then(blob => {
                        a.href = URL.createObjectURL(blob)                //blob地址
                        a.download = item.file_title                                   // 下载文件的名字
                        a.click()
                    })
                }

            } else {
                var file = item.file_url;
                var xurl = "https://view.xdocin.com/view?src=";
                xurl += encodeURIComponent(file);
                this.previewUrl = xurl;
                window.open(this.previewUrl)
            }

        }
    }
}
</script>
<style scoped lang="less">
/deep/.van-empty,
/deep/.van-loading {
    position: absolute;
    top: 28%;
    left: 50%;
    transform: translateX(-50%);
}

/deep/.van-empty {
    width: 100%;

    .van-empty__image {
        width: 300px;
        height: 300px;
    }
}

/deep/.van-loading {
    top: 50%;
}

@Color: var(--themecolor);

.fileBox::-webkit-scrollbar {
    display: none;
}

.fileBox {
    width: 100%;
    height: 100vh;
    overflow: scroll;
    max-width: 420px;
    background: #fff;
    .fileConter {
        width: 100%;
        min-height: 95%;

        .search {
            position: sticky;
            top: 0;
        }

        ul {
            width: 100%;
            margin: 0 auto;
            display: flex;

            .conbox {
                width: 100%;
                height: 100%;
                display: flex;
                align-items: center;
                border-bottom: 1px solid #EBEDF0;
                padding: 10px;

                .conleft {
                    width: 60px;
                    height: 60px;
                    padding: 10px;
                    display: flex;
                    align-items: center;
                    /*垂直居中*/
                    justify-content: center;
                    /*水平居中*/
                    background: #FAFAFA;

                    img {
                        width: 30px;
                    }
                }

                .conright {
                    flex: 1;
                    padding: 5px 10px;

                    .file_title {
                        margin-bottom: 5px;
                    }

                    p {
                        font-size: 14px;
                        color: #000000;
                        display: -webkit-box;
                        overflow: hidden;
                        word-break: break-all;
                        width: 100%;
                        -webkit-box-orient: vertical;
                        -webkit-line-clamp: 2;
                        line-height: 20px;
                    }

                    .bottomBox {
                        width: 100%;
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        font-size: 10px;

                        .rightBot {
                            width: 100%;
                            height: 100%;
                            width: 70%;
                            line-height: 14px;

                            span {
                                min-width: 30px;
                                display: inline-block;
                            }

                            .time {
                                color: #888888;
                            }

                            img {
                                width: 20px;
                            }
                        }

                        .leftBot {
                            width: 50px;
                            height: 30px;
                            border: 1px solid @Color;
                            border-radius: 3px;

                            &:last-child {
                                margin-left: 10px;
                            }

                            span {
                                text-align: center;
                                line-height: 30px;
                                color: @Color;
                                display: block;
                                font-weight: bold;
                            }
                        }
                    }
                }
            }
        }
    }
}

.iframe_box {
    background: #000;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;

    .cross {
        position: absolute;
        right: 15px;
        top: 10px;
        font-size: 20px;
        background: rgba(133, 133, 133, 0.3);
        color: #fff;
        width: 35px;
        height: 35px;
        text-align: center;
        line-height: 35px;
        border-radius: 35px;
    }

    .loading_box {
        width: 100%;
        height: 100%;
        background: #000;
    }
}
</style>