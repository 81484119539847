import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import Vant from 'vant';
import bus from './js/bus'
import 'swiper/css/swiper.css';
import 'vant/lib/index.css';
import '../src/styles/font.scss'
// mac桌面 所引入文件
import tool from './js/tool'
Vue.prototype.tool = tool
// end

import { Lazyload } from 'vant'
import BaiduMap from 'vue-baidu-map'
import axios from 'axios'
import * as qiniu from 'qiniu-js'
Vue.prototype.$qiniu = qiniu
import VueQr from 'vue-qr'
Vue.use(VueQr)
Vue.prototype.$bus = bus
Vue.use(ElementUI)
Vue.use(Vant);
Vue.use(Lazyload);

import VueCookies from 'vue-cookies';
Vue.use(VueCookies)
window.$cookies.config('10d');
Vue.config.productionTip = false;


Vue.use(BaiduMap, {
  ak: 'C2iBA6XSE3EMIzMAVqgp5v4h6PsORQ2O'
})

axios.interceptors.request.use(
  config => {
    if (VueCookies.get('microtoken')) {  // 判断是否存在token，如果存在的话，则每个http header都加上token
      config.headers.token = VueCookies.get('microtoken')
    }
    return config;
  }, err => { return Promise.reject(err); }
);
axios.interceptors.response.use(res => {
  //站点不存在
  if (res.data.code == 3000) {
    store.commit('changeErrCode', 3000)
    router.push('/stationnull')
  }
  let ua = navigator.userAgent.toLowerCase()
  if(ua.indexOf('micromessenger') !== -1) {
    if(res.data.code == 401 ) {
      VueCookies.remove('microtoken')
      // router.push({'name': 'home',
      //   params: {
      //     site_url: sessionStorage.getItem('siteUrl')
      //   }
      // })
      let index = window.location.href.indexOf(sessionStorage.getItem('siteUrl'))
      let url =window.location.href.substring(0,index)+ sessionStorage.getItem('siteUrl')
      window.location.replace(url)
      // document.location.reload();
    }
  }
  return res
},
err => {
  return Promise.reject(err)
})
// 全局路由拦截
router.beforeEach((to,from,next)=>{
  const url = new URL(window.location.href);
  let ua = navigator.userAgent.toLowerCase()
  let isWeixin = ua.indexOf('micromessenger') !== -1 // 是否 在微信浏览器内
    // 判断是否是错误路由
    //  if(to.matched.length){
      //存siteurl
    if(to.fullPath.indexOf("/event/") != -1){
      // let index = to.fullPath.lastIndexOf("\/");
      // let indexw = to.fullPath.lastIndexOf("?code=");
      // let site_url = to.fullPath.substring(index + 1,indexw == -1 ? to.fullPath.length : indexw);
      // console.log(site_url);
      // window.sessionStorage.setItem('siteUrl',site_url);
      if ( sessionStorage.getItem('siteUrl') ) {
        sessionStorage.removeItem('siteUrl')
      }
      sessionStorage.setItem('siteUrl', to.params.site_url)
    }
    
     // 微信code不截取
     //发起微信授权
    if(url.searchParams.get("code")){
      let code = url.searchParams.get("code");
      if(sessionStorage.getItem('microcode')){
        sessionStorage.removeItem('microcode')
      }
      sessionStorage.setItem('microcode', code)
    }
    if(to.fullPath.indexOf("?referral_code") != -1 && to.name == 'Home'){//home首页
      // 截取url，去除参数
      let skipurl = to.fullPath.split("?referral_code=")[0];
      // 判断referral_code 没有多余参数
      let referralCode = ''
      if(to.fullPath.indexOf("&") != -1){
        let startIndex = to.fullPath.indexOf("referral_code=")
        let endIndex = to.fullPath.indexOf("&")
        referralCode = to.fullPath.substring((startIndex + 14),endIndex)
      }else{
        referralCode = to.fullPath.split("?referral_code=")[1];
      }
      window.sessionStorage.setItem("referralCode", referralCode);
      next({path:skipurl})
    }else{
      next();
    }
    !sessionStorage.getItem('coverStatus') && sessionStorage.setItem('coverStatus',0);
  // }
})
  

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
