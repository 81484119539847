<template>
<div style="height:100%">
    <div class="schedule-info" id="scroll"  v-if="!Loading">
        <div style="min-height:95%;">
            <div class="top">
                <div class="info-top">
                    <div class="left-top" v-if="scheduleDate.length>0">
                        <span>{{info.venue_time!=0?timestampToTime(info.venue_time):timestampToTime(scheduleDate[0].start_time*1000)}}</span>
                    </div>
                    <div class="right-top" @click="liveUrl">
                        <img src="@/assets/schedule/video.svg" alt="">
                        <span>进入直播</span>
                    </div>
                </div>
                <div class="position" v-if="scheduleDate.length>0||info.venue_position">
                    <p class="date" v-if="scheduleDate.length>0"><img src="@/assets/RiCheng/white_time.svg" alt="" >{{scheduleDate[0].start_time_format}}-{{scheduleDate[scheduleDate.length-1].end_time_format}}</p>
                    <p v-if="info.venue_position"><img src="@/assets/RiCheng/site.svg" alt="">{{info.venue_position}}</p>
                </div>
            </div>
            <div class="bottom">
                <div class="assembly_name">
                    {{info.venue_title}}
                </div>
                <div v-for="(item,index) in scheduleDate" :key="index" class="bottom_box">
                    <div class="details_steps">
                        <div class="details_steps_icon"></div>
                        <div class="details_steps_line"></div>
                    </div>
                    <div class="bottom_right">
                        <div class="bottom_time">{{item.start_time_format}}-{{item.end_time_format}}</div>
                        <div class="bottom_warp" v-for="(j,k) in item.people_library" :key="k" >
                            <div class="bottom_library">{{k==1?info.venue_guest:info.venue_host}}</div>
                            <div v-for="(i,kep) in item.people_library[k]" :key="kep" class="bottom_info" @click.stop="goAttendInfo(i.id,i)">
                                    <div class="bottom_img" :style="{background:i.avatar_url&&i.avatar_id!=3?'':themecolors}">
                                        <img :src="i.avatar_url" alt="" v-if="i.avatar_url&&i.avatar_id!=3">
                                        <div v-else>
                                            {{i.name.substr(0,1)}}
                                        </div>
                                    </div>
                                    <div class="bottom_name">
                                        <div>{{i.name}}</div>
                                        <div class="bottom_name_hospital">{{i.hospital}}</div>
                                    </div>
                                    <div class="item-right"><img src="@/assets/schedule/arrow-right.svg" alt=""></div>
                            </div>
                        </div>
                        <div class="bottom_library" style="padding-left: 10px;" v-if="item.people_library.length==0">
                            暂无人员安排
                        </div>
                    </div>
                   
                </div>
            </div>
        </div>
        <Support></Support>
    </div>
    <van-loading :color="themecolors" :text-color="themecolors" v-else />
</div>
</template>

<script>
import Support from "@/components/support";
import { mapState } from "vuex"; //引入vuex
export default {
  components: {
    Support,
  },
  data() {
    return {
      activeNames: ["1"],
      config: {},
      info: {},
      scheduleDate: [],
      timeline: [],
      introduction: "",
      select: 0,
      timelineArr: [],
      showAttention: false,
      Loading:true,
    };
  },
  props:{
    app:Object
  },
  created() {
    this.info = JSON.parse(
      window.sessionStorage.getItem("scheduleTimelineCurrentItem")
    );
    if(!isNaN(this.info.venue_time)){
        this.info.venue_time=this.info.venue_time*1000
    }
    this.get_academic_assignments_info();
    if (
      sessionStorage.getItem("user_header") == 1 &&
      this.$cookies.isKey("microtoken")
    ) {
      this.showAttention = true;
    } else {
      this.showAttention = false;
    }
  },
  computed: {
    ...mapState(["themecolors"]),
  },
  methods: {
     // 转日期格式
    timestampToTime(time) {
      // 时间戳为10位需*1000，时间戳为13位的话不需乘1000
      let date = new Date(time);
      let y = date.getFullYear();
      let MM = date.getMonth() + 1;
      MM = MM < 10 ? "0" + MM : MM;
      let d = date.getDate();
      d = d < 10 ? "0" + d : d;
      let h = date.getHours();
      h = h < 10 ? "0" + h : h;
      let m = date.getMinutes();
      m = m < 10 ? "0" + m : m;
      let s = date.getSeconds();
      s = s < 10 ? "0" + s : s;
      return MM + "-" + d;
    },
    get_academic_assignments_info() {
      this.$store
        .dispatch("get_academic_assignments_info", {
          venue_id: this.app.data.id,
        })
        .then((res) => {
          if (res.data.code == 200) {
            this.scheduleDate = res.data.data;
            this.Loading=false
          }
        });
    },
    getLocalTime(time, type) {
      var now = new Date(time);
      var year = now.getFullYear();
      var month = now.getMonth() + 1;
      var date = now.getDate();
      var hour = now.getHours();
      var minute = now.getMinutes();
      var second = now.getSeconds();
      if (type == 1)
        return (
          year +
          "-" +
          (month < 10 ? "0" + month : month) +
          "-" +
          (date < 10 ? "0" + date : date)
        );
      if (type == 2)
        return (
          (hour < 10 ? "0" + hour : hour) +
          ":" +
          (minute < 10 ? "0" + minute : minute) +
          ":" +
          (second < 10 ? "0" + second : second)
        );
      if (type == 3)
        return (
          (month < 10 ? "0" + month : month) +
          "月" +
          (date < 10 ? "0" + date : date) +
          "日"
        );
    },
    liveUrl() {
      let item = sessionStorage.getItem('wei_live_message') && JSON.parse(sessionStorage.getItem('wei_live_message'));
      if (!item.live_studio_url) {
        return this.$toast({
          message: "当前会议未添加直播间!",
        });
      }
      if (item.type == 1) {
        let obj = {
          studio_id: item.studio_id,
          site_id: this.$cookies.get('site_id'),
          form_id: this.$cookies.get("form_user_id"),
        };
        this.$store
          .dispatch("get_user_form_identity", obj)
          .then((res) => {
            if (res.data.code == 200) {
              location.href =
                item.live_studio_url + "?liguserinfo=" + res.data.data.token;
            }
            if (res.data.code == 206) {
              location.href = item.live_studio_url;
            }
          })
          .catch((err) => {
            location.href = item.live_studio_url;
          });
        return
      }
      location.href = item.live_studio_url;
    },
    doctorClick(item) {
      this.timelineArr = [];
      this.introduction = item.introduction;
      this.timeline.forEach((i, j) => {
        i.doctor.forEach((a) => {
          item.id == a.id ? this.timelineArr.push(i) : "";
        });
      });
    },
    goAttendInfo(id,i){
         this.$cookies.set("people_id", id);
          window.sessionStorage.setItem("attendIntro",JSON.stringify(i))
          let obj = {
                component: "AttendIntro",
                hide: false,
                height: 700,
                width: 420,
                key: 'demo_attendIntro',
                module_title: "嘉宾简介",
                reload:true,
                pid: new Date().valueOf() + "." + parseInt(Math.random() * 99999999),
                data: {
                    ...this.params,
                }
            }
            this.$store.commit("openApp", obj);
    },
    //关注或取消关注日程
    async attentionSchedule() {
      if (this.$cookies.isKey("microtoken") == false) {
        this.$toast("请用微信浏览器打开！");
        return;
      }
      let res;
      if (this.info.follow_status == 0) {
        res = await this.$store.dispatch("add_follow_the_schedule", {
          id: this.info.id,
          site_id: this.app.data.site_id,
          page_id: this.app.data.page_id,
          module_id: this.app.data.module_id,
        });
      } else {
        res = await this.$store.dispatch(
          "delete_follow_the_schedule",
          this.info.id
        );
      }
      if (res.data.code == 200) {
        if (this.info.follow_status == 0) {
          this.info.follow_status = 1;
        } else {
          this.info.follow_status = 0;
        }
        window.sessionStorage.setItem(
          "scheduleTimelineCurrentItem",
          JSON.stringify(this.info)
        );
        this.$toast(res.data.message);
      } else {
        this.$toast(res.data.message);
      }
    },
  },
};
</script>

<style lang='less' scoped>
@Color:var(--themecolor);
@bgcolor:var(--themecolor);
.schedule-info {
  background: #f2f6f8;
  height: 100%;
  box-sizing: border-box;
  overflow-y: scroll;
  max-width: 420px;
  margin: 0 auto;
  .top {
    width: 100%;
    padding: 21px 16px 14px;
    background: linear-gradient(180deg, #94a7be 0%, #b9c8da 100%);
    .info-top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left-top {
        overflow: hidden;
        display: flex;
        span {
          display: inline-block;
          overflow: hidden;
          font-size: 29px;
          color: #fff;
          // font-family: 'DinBold';
          font-weight: 600;
          max-width: 180px;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .week {
          margin-left: 12px;
          margin-top: 16px;
          font-size: 16px;
          // font-family: 'PingFang';
        }
      }
      .right-top {
        padding: 9px 14px;
        background: #b9c7d5;
        height: 40px;
        border-radius: 20px;
        display: flex;
        img {
          width: 18px;
          height: auto;
        }
        span {
          color: #fff;
          margin-left: 9px;
          font-size: 16px;
          // font-family: 'PingFangBold';
        }
      }
    }
    .position {
      display: flex;
      align-items: center;
      margin-top: 25px;
      padding: 0 0 15px 7px;
      margin-bottom: 10px;
      font-size: 12px;
      color: #fff;
      border-bottom: 1px solid #fff;
      .date {
        margin-right: 70px;
        font-weight: 600;
        min-width: 90px;
      }
      p {
        display: flex;
        align-items: center;
      }
      img {
        margin-right: 12px;
        width: 12px;
        height: auto;
      }
    }
    .time {
      margin-top: 25px;
      padding: 0 0 15px 7px;
      display: flex;
      border-bottom: 1px solid #ffffff;
      justify-content: flex-start;
      p {
        font-size: 12px;
        color: #fff;
        // font-family: 'PingFangBold';
        img {
          margin-right: 12px;
          width: 12px;
          height: auto;
        }
      }
      .date {
        margin-right: 70px;
        // font-family: 'DinMedium';
        font-weight: 600;
        min-width: 90px;
      }
    }
    .meeting-title {
      font-size: 14px;
      color: #fff;
      margin-top: 15px;
      padding-left: 7px;
      word-break: break-all;
    }
  }
  .bottom {
    margin-top: -20px;
    padding-bottom: 24px;
    background: #fff;
    border-radius: 10px 10px 0 0;
      .assembly_name{
        font-size: 16px;
        margin-top: 15px;
         padding: 24px 16px;
        word-break: break-all;
        font-weight: 600;
    }
    .bottom_box {
      display: flex;
      width: 100%;
      padding: 0 16px;
      font-size: 14px;
      &:last-child{
        .details_steps .details_steps_line{
            display: none;
        }
      }
      .bottom_time {
        display: flex;
        align-items: center;
        padding: 5px 14px;
        background: #f0f2fa;
        border-radius: 17px;
        width: max-content;
      }
      .bottom_right{
        flex:1;
        padding-bottom: 24px;
      }
        .details_steps {
          display: flex;
          flex-direction: column;
          align-items: center;
          margin-right: 10px;
          .details_steps_icon {
            width: 10px;
            height: 10px;
            background: #e5e6eb;
            border-radius: 50%;
            margin: 4px 0 8px;
          }
          .details_steps_line {
            flex: 1;
            width: 1px;
            background: #e5e6eb;
            margin-bottom: 4px;
          }
        }
      .bottom_warp{
        padding-left: 10px;
      }
      .bottom_library{
        margin: 10px 0 20px 0;
      }
      .bottom_info{
        display: flex;
        align-items: center;
        margin-top: 16px;
        &:first-child{
            margin-top: 0;
        }
      }
      .bottom_img{
        width: 40px;
        height: 40px;
        text-align: center;
        line-height: 40px;
        border-radius: 50%;
        overflow: hidden;
        position: relative;
        color: #fff;
        margin-right: 10px;
        img{
            width: 100%;
            object-fit: cover;
        }
      }
      .bottom_name{
        color: #1f2129;
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        flex:1;
        .bottom_name_hospital{
            font-size: 12px;
            color: #969799;
        }
      }
    }
  }
}
/deep/.van-loading{
    position: absolute;
    top: 50%;
    left:50%;
    transform:translate(-50%,-50%);
}
</style>